import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Trading Solutions</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Trading Solutions</p>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/apparel.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h1 className="display-6 mb-0">Apparel</h1>
                </div>
                <p>
                  Situated at the epicenter of cotton and textile production,
                  India emerges as an abundant reservoir of raw materials that
                  form the foundation of the apparel industry. Amidst this
                  vibrant panorama, we position ourselves as catalysts,
                  committed to empowering mill owners, producers, and
                  wholesalers in effectively navigating the global trade of
                  these precious textile resources. Our mission resonates as we
                  facilitate the fluid import and export of these indispensable
                  commodities to every corner of the globe. At our essence, we
                  adopt the role of guardians, meticulously overseeing the
                  intricate global distribution network of textiles, apparel,
                  and accessories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-end border-5 border-primary pe-4 mb-5 text-end">
                  <h1 className="display-6 mb-0 ">Wood</h1>
                </div>
                <p>
                  India shines as a remarkable exporter, particularly in the
                  domain of wood products. The art of wood trading unveils a
                  wide array of offerings tailored to various market segments.
                  This trade rests upon a deep comprehension of market
                  intricacies, enabling the crafting of finished products of
                  unparalleled quality, finely attuned to the specific demands
                  of their intended market. Within this intricate landscape, our
                  organization emerges as a seasoned player, specializing in the
                  trading of logs, lumber, and woodchips sourced from lush
                  forests. Our expertise goes beyond the transactional aspect;
                  it encompasses the entire tapestry of the trade process,
                  extending from the initial harvest to the ultimate supply to
                  end-users.
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/wood.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/food.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h1 className="display-6 mb-0">Food & Beverages</h1>
                </div>
                <p>
                  To cater to the worldwide craving for delightful beverages,
                  producers and distributors must guarantee the swift and
                  efficient flow of these products. At {companyname}, we deliver prompt and reliable transportation
                  solutions to diverse destinations. Thus, no matter the nature
                  of the beverages being transported, our experts stand ready to
                  provide the seamless support necessary for your fluid supply
                  chain needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-end border-5 border-primary pe-4 mb-5 text-end">
                  <h1 className="display-6 mb-0 ">Pharma</h1>
                </div>
                <p>
                  Amid the vast expanse of India's pharmaceutical sector, a
                  resilient and firmly established market flourishes, offering a
                  welcoming environment to a plethora of global enterprises.
                  Despite the availability of a diverse range of products, the
                  pricing framework maintains its rationality. This convergence
                  of elements stands as evidence of the substantial presence and
                  sustainability of the Indian pharmaceutical market. It is
                  within this dynamic context that we step forward as
                  facilitators, providing a gateway to acquire top-notch
                  pharmaceutical products from internationally renowned
                  companies.
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/pharma.webp"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/chemical.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h1 className="display-6 mb-0">Chemicals & Petrochemicals</h1>
                </div>
                <p>
                  With meticulous attention, exacting standards, and unwavering
                  procedures, {companyname} ensures the safe
                  conveyance of hazardous and potentially harmful materials,
                  such as chemicals and petrochemicals. As seasoned authorities
                  in transporting dangerous cargo, {companyname}
                  pledges the secure delivery of your consignments while
                  adhering to relevant regulations. Moreover, we are poised to
                  aid you in handling unforeseen contingencies, irrespective of
                  your shipping destination across the globe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
