import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      {/* Carousel Start */}
      <div
        className="container-fluid p-0 mb-5 wow fadeIn "
        data-wow-delay="0.1s"
      >
         
        <div
          id="header-carousel"
          className="carousel slide  "
          data-bs-ride="carousel"
        >
          <div className="carousel-inner ">
            <div className="carousel-item active w3-banner jarallax">
            <img className="w-100" src="img/carousel-2.jpg" alt="Image" style={{position:"inherit", zIndex:"-1"}}/>
            
            <video
                className="img-fluid"
                autoPlay
                muted
                loop
                style={{ zIndex: "-1" }}
              >
                <source src="img/video.mp4" type="video/mp4" />
              </video>
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                        Welcome to {companyname}
                      </h5>
                      <h1 className="display-2 text-light mb-3 animated slideInDown">
                      Delivering Beyond Expectations
                      </h1>
                      <ol className="breadcrumb mb-4 pb-2">
                      <li className="breadcrumb-item fs-5 text-light">
                        </li>
                        <li className="breadcrumb-item fs-5 text-light">
                          Freight Forwarding Solutions
                        </li>
                        <li className="breadcrumb-item fs-5 text-light">
                          Trading Solutions
                        </li>
                      </ol>
                      <Link to="/About" className="btn btn-primary py-3 px-5">
                        More Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item  w3-banner jarallax">
            <img className="w-100" src="img/carousel-2.jpg" alt="Image" style={{position:"inherit", zIndex:"-1"}}/>
            
            <video
                className="img-fluid"
                autoPlay
                muted
                loop
                style={{ zIndex: "-1" }}
              >
                <source src="img/video.mp4" type="video/mp4" />
              </video>
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <h5 className="text-light text-uppercase mb-3 animated slideInDown">
                        Efficient Freight Forwarding and Trading Solutions
                      </h5>
                      <h1 className="display-2 text-light mb-3 animated slideInDown">
                      Your Success, Our Commitment
                      </h1>
                      <ol className="breadcrumb mb-4 pb-2">
                      <li className="breadcrumb-item fs-5 text-light">
                        </li>
                        <li className="breadcrumb-item fs-5 text-light">
                          Freight Forwarding Solutions
                        </li>
                        <li className="breadcrumb-item fs-5 text-light">
                          Trading Solutions
                        </li>
                      </ol>
                      <Link to="/About" className="btn btn-primary py-3 px-5">
                        More Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/about.jpeg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                    
                    <h5 className="text-white mb-0">We Are {companyname}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h6 className="text-body text-uppercase mb-2">About Us</h6>
                  <h1 className="display-6 mb-0">
                  Empowering Commerce through Expert Freight Forwarding & Trading!
                  </h1>
                </div>
                <p>{companyname} is a publicly listed freight forwarding and trading service provider company. Our approach is rooted in cutting-edge technology, aiming to deliver tailor-made logistic solutions globally to our clients. {companyname} specializes in catering to various shipment types, including perishables, time-sensitive express shipments, general cargo, ODC, hazardous cargo, and refrigerated cargo, using multiple transport modes such as air, sea, rail, and road. Additionally, we are an authorized customs clearing agency, efficiently managing over 150 tonnes of air cargo around the clock, every day. Our commitment and performance have earned us prestigious certifications from both Indian and international airlines and institutions.</p>

                <div className="border-top mt-4 pt-4">
                  <div className="row g-4">
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.1s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Customized Solutions</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">24/7 Support & Help</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Professional Workforce</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
     
      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Services</h6>
                <h1 className="display-6 mb-0">
                  Freight Forwarding Services We Provide..
                </h1>
              </div>
            </div>
            <div
              className="col-lg-6 text-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
             
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/air.webp" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Air Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Air">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/sea.jpeg" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Sea Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Sea">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/rail.png" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Rail Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Rail">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/road.jpg" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Surface Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Surface">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/customs.png" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Customs Clearance</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Customs">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/warehousing.webp" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Warehousing</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Warehousing">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
     
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Services</h6>
                <h1 className="display-6 mb-0">Trading Services We Offer...</h1>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/apparel.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Apparel</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/wood.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Wood</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/food.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Food & Beverages</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/pharma.webp" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Pharma</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/chemical.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Chemicals & Petrochemicals</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
   
      <Footer />
    </>
  );
}
