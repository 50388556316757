import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">About Us</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">About Us</p>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/about.jpeg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                    
                    <h5 className="text-white mb-0">We Are {companyname}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h6 className="text-body text-uppercase mb-2">About Us</h6>
                  <h1 className="display-6 mb-0">
                  Empowering Commerce through Expert Freight Forwarding & Trading!
                  </h1>
                </div>
                <p>{companyname} is a publicly listed freight forwarding and trading service provider company. Our approach is rooted in cutting-edge technology, aiming to deliver tailor-made logistic solutions globally to our clients. {companyname} specializes in catering to various shipment types, including perishables, time-sensitive express shipments, general cargo, ODC, hazardous cargo, and refrigerated cargo, using multiple transport modes such as air, sea, rail, and road. Additionally, we are an authorized customs clearing agency, efficiently managing over 150 tonnes of air cargo around the clock, every day. Our commitment and performance have earned us prestigious certifications from both Indian and international airlines and institutions.</p>

                <div className="border-top mt-4 pt-4">
                  <div className="row g-4">
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.1s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Customized Solutions</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">24/7 Support & Help</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Professional Workforce</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      <Footer />
    </>
  );
}
