import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Surface Freight</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Surface Freight</p>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="pb-3">
              <div className="position-relative">
                <img className="img-fluid w-100" src="img/road.jpg" alt="" />
              </div>
              <div className=" mb-3" style={{ padding: "30px" }}>
                <h3 className="font-weight-bold mb-3">Surface Freight</h3>
                <p>
                {companyname} emerges as a distinguished player in
                  the realm of surface transportation, connecting vital
                  destinations seamlessly. Our expertise lies in delivering
                  efficient and customized surface freight solutions that
                  translate into tangible benefits for our esteemed clients.
                  Powered by a robust network and a seasoned workforce, we
                  ensure the fluid movement of goods and a range of solutions
                  that cater to the distinct needs of every client.
                </p>

                <p>
                  One of our core strengths lies in our adept handling of a
                  variety of cargo types, ranging from general goods to
                  specialized shipments. Recognizing the uniqueness of each
                  cargo, we offer tailored solutions to ensure safe and timely
                  delivery. Our skilled team is well-equipped to tackle any
                  challenges that may arise during the transportation process.
                </p>

                <p>
                  At {companyname}, we take immense pride in
                  offering comprehensive services that cover the entire spectrum
                  of surface transportation. Whether it's road or rail, our
                  offerings encompass seamless movement for both domestic and
                  international shipments. Our expansive service network spans
                  various regions, ensuring that your cargo reaches its
                  destination efficiently and on time.
                </p>

                <p>
                  Our strong network of partners and carriers empowers us to
                  provide a range of surface freight services that prioritize
                  reliability, flexibility, and cost-effectiveness. Our prowess
                  extends to handling different types of cargo, ensuring that
                  each consignment is treated with the utmost care and
                  precision.
                </p>

                <p>
                {companyname} is committed to delivering
                  personalized attention to each client. Our dedicated team of
                  professionals collaborates closely with clients to understand
                  their unique transportation needs, enabling us to provide
                  tailored solutions that align perfectly with their
                  requirements. We offer real-time tracking and updates to keep
                  our clients informed about their shipment's progress. Our team
                  is available around the clock to address any queries or
                  concerns.
                </p>

                <p>
                  We understand that navigating the world of transportation can
                  be complex, especially for businesses new to the industry. To
                  assist our clients, we offer consultation services that
                  provide insights into the transportation process. Our experts
                  offer guidance on documentation, regulatory compliance, and
                  other aspects, ensuring our clients are well-prepared for the
                  journey.
                </p>

                <p>
                  Transparency and competitive pricing are the cornerstones of
                  {companyname}'s philosophy. Our pricing structure reflects our
                  commitment to providing high-quality services at reasonable
                  costs, fostering enduring relationships with our clients.
                </p>

                <p>
                  {companyname} stands as a dynamic force in the
                  surface transportation arena, seamlessly connecting
                  destinations across the map. Our proficiency in tailored
                  surface freight solutions results in tangible benefits for our
                  clients. With a formidable network and expert insights, we
                  ensure the smooth movement of goods and a suite of solutions
                  that cater to every client's unique needs.
                </p>
              </div>
            </div>
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
              <h3 className="mb-4">All Freight Forwarding Solutions</h3>
              <div className="" style={{ padding: "30px" }}>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Sea">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Sea Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Air">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Air Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Surface">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Surface Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Rail">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Rail Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Customs">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Customs Clearance
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Warehousing">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Warehousing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      <Footer />
    </>
  );
}
