import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      <div className="container-fluid bg-light p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-5 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center border-start border-end px-3">
              <small className="fa fa-phone-alt me-2" />
              <small>{companynumber}</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center border-end px-3">
              <small className="far fa-envelope-open me-2" />
              <small>{companyemail}</small>
            </div>
          </div>
          <div className="col-lg-7 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center border-start border-end px-3">
              <Link to="/Privacy"><small>Privacy Policy</small></Link>
            </div>
            <div className="h-100 d-inline-flex align-items-center border-end px-3">
            <Link to="/Terms"><small>Terms</small></Link>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
        <Link
          to="/Home"
          className="navbar-brand d-flex align-items-center"
        >
          <img src="img/logo.png" style={{ height: "100px" }}></img>
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-3 py-lg-0">
            <Link to="/Home" className="nav-item nav-link active">
              Home
            </Link>
            <Link to="/About" className="nav-item nav-link">
              About Company
            </Link>
           
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Freight Solutions
              </Link>
              <div className="dropdown-menu bg-light m-0">
                <Link to="/Air" className="dropdown-item">
                  Air Freight
                </Link>
                <Link to="/Sea" className="dropdown-item">
                  Sea Freight
                </Link>
                <Link to="/Rail" className="dropdown-item">
                  Rail Freight
                </Link>
                <Link to="/Surface" className="dropdown-item">
                  Surface Freight
                </Link>
                <Link to="/Customs" className="dropdown-item">
                  Customs Clearance
                </Link>
                <Link to="/Warehousing" className="dropdown-item">
                  Warehousing Service
                </Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Trading Solutions
              </Link>
              <div className="dropdown-menu bg-light m-0">
                <Link to="/Trading" className="dropdown-item">
                Apparel
                </Link>
                <Link to="/Trading" className="dropdown-item">
                Wood
                </Link>
                <Link to="/Trading" className="dropdown-item">
                Food & Beverages
                </Link>
                <Link to="/Trading" className="dropdown-item">
                Pharma
                </Link>
                <Link to="/Trading" className="dropdown-item">
                Chemicals & Petrochemicals
                </Link>
                


              </div>
            </div>
            <Link to="/Contact" className="nav-item nav-link">
              Contact Us
            </Link>
            <Link  className="nav-item nav-link">
            
            </Link>
          </div>
        </div><Link to="/Getquote"className="btn btn-primary py-2  top-0 end-0 mt-2 me-2">REQUEST A QUOTE</Link>
      </nav>
    </>
  );
};

export default Header;
