import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{ background: " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Sea Freight</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Sea Freight</p>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="pb-3">
              <div className="position-relative">
                <img className="img-fluid w-100" src="img/sea.jpeg" alt="" />
              </div>
              <div className=" mb-3" style={{ padding: "30px" }}>
                <h3 className="font-weight-bold mb-3">Sea Freight</h3>
                <p>
                  {companyname} stands as a prominent player in the ocean
                  freight realm, conducting operations across key seaports
                  globally. Our forte lies in delivering tailored ocean freight
                  solutions that translate into economic triumphs for our valued
                  clients. Backed by an unassailable agency network and a
                  seasoned workforce, we ensure fluid movement and a spectrum of
                  solutions that cater to the distinct demands of every client.
                </p>

                <p>
                  One of our cardinal strengths rests in our adeptness at
                  handling heavy lift, over-dimensional cargos, and breakbulk
                  cargos. Recognizing the individuality of each cargo and its
                  requisite handling, we present bespoke solutions to guarantee
                  the secure and punctual delivery of your consignments. Our
                  skilled team is adept at tackling any challenge that may arise
                  during the intricate shipping journey.
                </p>

                <p>
                  At {companyname}, we take immense pride in extending
                  end-to-end services that envelop the entire shipping spectrum.
                  Our offerings encompass door-to-door movement for both inbound
                  and outbound shipments from India to various global
                  destinations. Our service footprint spans the USA, East &
                  Upper Gulf, Far East and Southeast Asia, Europe, and Africa.
                  Our astute comprehension of the distinctive hurdles faced when
                  shipping to diverse regions empowers us to furnish
                  personalized solutions meticulously tailored to fulfill the
                  exact requisites of each client.
                </p>

                <p>
                  Our robust agency network envelopes major seaports across the
                  globe. Our strategic alliances with leading carriers and
                  esteemed shipping lines empower us to present a gamut of ocean
                  freight services that encapsulate reliability, adaptability,
                  and cost-effectiveness. Whether it's full-container-load
                  (FCL), less-than-container-load (LCL), or breakbulk
                  consignments, our prowess extends to mastering diverse cargo
                  types.
                </p>

                <p>
                  {companyname} is steadfast in its commitment to personalized
                  client attention. Our dedicated team of professionals works in
                  close concert with each client to comprehend their distinct
                  shipping prerequisites, thereby proffering tailor-made
                  solutions that perfectly align with their needs. Real-time
                  tracking and updates are offered to keep our clients
                  well-informed about their shipment's progress. Our team
                  remains at your disposal around the clock to address any
                  queries or concerns that may arise.
                </p>

                <p>
                  We acknowledge that the shipping landscape can appear
                  intricate and challenging, particularly for businesses new to
                  the industry. To this end, we extend consultation services to
                  empower our clients with a comprehensive understanding of the
                  shipping process. Our experts provide adept guidance on
                  documentation, customs clearance, and other regulatory facets,
                  ensuring our clients are poised to navigate the shipping
                  journey with utmost confidence.
                </p>

                <p>
                  Transparency and competitive pricing form the bedrock of{" "}
                  {companyname}'s ethos. Our pricing structure stands as a
                  reflection of our dedication to fostering enduring
                  relationships with our clients. We believe in furnishing
                  top-notch services at a reasonable cost, cementing our
                  commitment to delivering quality and value.
                </p>

                <p>
                  {companyname} remains a prominent player in the ocean freight
                  domain, traversing major seaports globally. Our specialization
                  in tailored ocean freight solutions translates into economic
                  triumphs for our clients. With a formidable agency network and
                  seasoned expertise, we ensure seamless movement and
                  diversified solutions that cater to each client's unique
                  requirements.
                </p>
              </div>
            </div>
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
              <h3 className="mb-4">All Freight Forwarding Solutions</h3>
              <div className="" style={{ padding: "30px" }}>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Sea">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Sea Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Air">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Air Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Surface">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Surface Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Rail">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Rail Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Customs">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Customs Clearance
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Warehousing">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Warehousing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      <Footer />
    </>
  );
}
