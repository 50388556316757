import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Rail Freight</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Rail Freight</p>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="pb-3">
              <div className="position-relative">
                <img className="img-fluid w-100" src="img/rail.png" alt="" />
              </div>
              <div className=" mb-3" style={{ padding: "30px" }}>
                <h3 className="font-weight-bold mb-3">Rail Freight</h3>
                <p>
                {companyname} emerges as a global freight
                  forwarding powerhouse, specializing in crafting bespoke
                  logistics solutions tailored to businesses of all scales.
                  While our acclaim rests with our air and ocean freight
                  services, we also extend our expertise to encompass rail
                  transportation solutions, catering to our clients' diverse
                  requirements.
                </p>

                <p>
                  Rail transportation stands as a pivotal mode of cargo
                  conveyance in India and beyond. It presents a cost-effective
                  and eco-friendly approach to ferrying substantial cargo
                  volumes across extensive distances. At {companyname}, a dedicated team of experts boasts extensive proficiency
                  in rail transportation. Our end-to-end solutions span
                  door-to-door transit, cargo handling and storage, as well as
                  customs clearance and documentation.
                </p>

                <p>
                  In India, our expansive network powers a comprehensive array
                  of rail transportation services connecting major cities and
                  industrial hubs. Our range of rail freight services
                  encompasses parcel transportation, PTL and TL freight, and
                  cross-border services. Regardless of the size or intricacy of
                  the shipment, our rail transportation solutions are
                  meticulously tailored to each client's unique requisites.
                </p>

                <p>
                  Our global reach transcends the Indian boundaries. {companyname}'s worldwide network of rail transportation
                  partnerships enables us to deliver rail freight services
                  spanning the globe. We have cultivated alliances with premier
                  rail carriers across the world, enabling us to present
                  seamless, dependable, and cost-efficient rail transportation
                  solutions.
                </p>

                <p>
                  Notable for its capacity to ferry substantial cargo volumes in
                  a single voyage, rail transportation emerges as a significant
                  advantage. Our rail freight services prove invaluable to
                  businesses needing to convey substantial goods across
                  extensive distances. We handle diverse cargo categories,
                  encompassing heavy machinery, raw materials, finished
                  products, and consumer goods.
                </p>

                <p>
                  Aside from its prowess, rail transportation champions
                  environmental sustainability. This mode produces fewer carbon
                  emissions than road transportation, rendering it an ideal
                  choice for businesses aiming to reduce their carbon footprint.
                  At {companyname}, we champion sustainability and
                  collaborate closely with our clients to forge eco-conscious
                  transportation solutions that suit their demands.
                </p>

                <p>
                  Timely delivery ranks paramount at {companyname}.
                  Our rail transportation services are meticulously designed to
                  ensure prompt and impeccable delivery of your cargo. Employing
                  cutting-edge technology and equipment, we track and monitor
                  your shipment throughout its journey, providing real-time
                  updates on its status.
                </p>

                <p>
                  Complementing our rail transportation offerings, we extend a
                  spectrum of value-added services, including warehousing,
                  distribution, and customs clearance. Our adept team of
                  logistics professionals remains available 24/7, poised to
                  offer unwavering support and assistance to our clients.
                </p>

                <p>
                {companyname}'s rail transportation services
                  epitomize a cost-effective and reliable approach to
                  transporting cargo across substantial distances. Leveraging
                  our global network, we deliver rail transportation solutions
                  meticulously tailored to your business's distinct
                  requirements. Reach out to us today to discover more about our
                  rail transportation services and how we can assist you in
                  conveying your cargo across India and the world.
                </p>
              </div>
            </div>
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
              <h3 className="mb-4">All Freight Forwarding Solutions</h3>
              <div className="" style={{ padding: "30px" }}>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Sea">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Sea Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Air">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Air Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Surface">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Surface Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Rail">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Rail Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Customs">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Customs Clearance
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Warehousing">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Warehousing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      <Footer />
    </>
  );
}
