import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
         {/* Page Header Start */}
         <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container text-center py-5">
            <h1 className="display-4 text-white animated slideInDown mb-4">Contact Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><Link to="text-white" href="/Home">Home</Link></li>
                <li className="breadcrumb-item text-primary active" aria-current="page">Contact Us</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Page Header End */}
        {/* Contact Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5">

              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight: '450px'}}>
                <div className="position-relative h-100">
                  <iframe className="position-relative w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4484.178323308306!2d73.00327002712548!3d19.07562811705446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1385adaa917%3A0x36d603953c5c0ec3!2sAPMC%20Market%2C%20Sector%2019%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!5e0!3m2!1sen!2sin!4v1691818750169!5m2!1sen!2sin" frameBorder={0} style={{minHeight: '450px', border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h6 className="text-body text-uppercase mb-2">Contact Us</h6>
                  <h1 className="display-6 mb-0">If You Have Any Query, Please Contact Us</h1>
                </div>
                <p className="mb-4">Ready to Optimize Your Shipments? Fill up the form now and experience hassle-free logistics excellence</p>
                <form
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname" required
                          name="fname"
                          className="form-control border-0 bg-light"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          onChange={(event) => handlelnameChange(event)}
                          className="form-control border-0 bg-light"
                          type="text" required
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          onChange={(event) => handleemailChange(event)}
                          className="form-control border-0 bg-light"
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          onChange={(event) => handlesubjectChange(event)}
                          className="form-control border-0 bg-light"
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <textarea
                          className="form-control border-0 bg-light"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        name="submit"
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>

      <Footer />
    </>
  );
}
