import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Warehousing</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Warehousing</p>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="pb-3">
              <div className="position-relative">
                <img className="img-fluid w-100" src="img/warehousing.webp" alt="" />
              </div>
              <div className=" mb-3" style={{ padding: "30px" }}>
                <h3 className="font-weight-bold mb-3">Warehousing</h3>
                <p>
                  In today's dynamic business landscape, organizations as
                  substantial as yours require a greater focus on
                  work-in-progress goods rather than merely accommodating
                  finished products. At {companyname}, we extend a diverse array
                  of solutions designed to optimize your time and space
                  allocation by offering proficient warehouse management. Our
                  spectrum of services spans from Intracity to Intercity,
                  encompassing local warehousing to international and domestic
                  courier services. Warehouse management, aptly dubbed the
                  backbone and cornerstone of our logistics management system,
                  plays a pivotal role in this comprehensive approach.
                </p>

                <p>
                {companyname} stands as a reputable warehousing authority,
                  catering to both import and export shipments. Our expansive
                  network of partners equips us to curate specialized solutions
                  for temperature-controlled cargo. We take charge of inventory
                  management, offering services such as repackaging and
                  palletization to ensure the streamlined movement of goods.
                </p>

                <p>
                  Our warehousing services extend far beyond conventional
                  storage. They embody an integrated approach that aligns
                  seamlessly with your operational needs. {companyname} is
                  committed to optimizing your inventory processes, enhancing
                  efficiency, and contributing to the overall operational
                  prowess of your organization. With our warehouse management
                  system, you can expect greater agility, improved control, and
                  a holistic approach to your storage needs.
                </p>
              </div>
            </div>
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
              <h3 className="mb-4">All Freight Forwarding Solutions</h3>
              <div className="" style={{ padding: "30px" }}>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Sea">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Sea Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Air">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Air Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Surface">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Surface Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Rail">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Rail Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Customs">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Customs Clearance
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Warehousing">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Warehousing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      <Footer />
    </>
  );
}
