import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Customs Clearance</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Customs Clearance</p>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="pb-3">
              <div className="position-relative">
                <img className="img-fluid w-100" src="img/customs.png" alt="" />
              </div>
              <div className=" mb-3" style={{ padding: "30px" }}>
                <h3 className="font-weight-bold mb-3">Customs Clearance</h3>
                <p>
                  For businesses engaged in import and export endeavors,
                  navigating the intricacies of customs clearance can prove
                  intricate and time-consuming. {companyname}
                  recognizes the significance of seamless cargo movement across
                  international borders and, thus, extends dependable and
                  efficient customs clearance services.
                </p>

                <p>
                  Operating as a licensed Customs House Agent (CHA), {companyname} boasts profound familiarity with the
                  convoluted regulatory requisites and procedures entailed in
                  customs clearance. Our team of experts exhibits adeptness in
                  managing diverse shipment types, encompassing air, ocean, and
                  surface transportation.
                </p>

                <p>
                  Above all, our clients take precedence, and our customs
                  clearance services mirror our unwavering commitment to
                  delivering unparalleled customer satisfaction. We proffer an
                  expansive spectrum of customs clearance solutions, spanning
                  shipment imports and exports, document preparation, customs
                  documentation, cargo inspection, and beyond. Businesses
                  entrusted to our care can rest assured that their cargo will
                  traverse customs sans delay or impediment.
                </p>

                <p>
                {companyname} acknowledges that each
                  business's requisites are unique, prompting us to devise
                  tailored solutions aligned with their specific demands. Our
                  adept professionals collaborate closely with clients,
                  capturing their specific needs and furnishing personalized
                  strategies that streamline their customs clearance journey.
                </p>

                <p>
                  Our customs clearance expertise transcends geographical
                  boundaries. Bolstered by a global network of partners and
                  agents, we deliver customs clearance services across prominent
                  nations worldwide. This extensive reach empowers us to handle
                  an array of shipment categories, including e-commerce
                  entities, SMEs, and prominent enterprises and brands.
                </p>

                <p>
                  Our commitment encompasses comprehensive customs clearance
                  solutions, spanning the entire process from pre-clearance to
                  post-clearance stages. We ensure meticulous preparation and
                  timely submission of requisite documents while adhering
                  meticulously to customs regulations and procedures.
                </p>

                <p>
                  Beyond customs clearance, a diverse suite of value-added
                  services bolsters the overall transportation experience. Our
                  offerings encompass freight forwarding, warehousing,
                  packaging, and transportation insurance.
                </p>

                <p>
                {companyname} takes center stage as the
                  trusted partner for businesses seeking reliable and efficient
                  customs clearance services. With our extensive experience,
                  global outreach, and customer-centric ethos, we deliver
                  bespoke solutions optimizing the customs clearance voyage,
                  ensuring unobstructed customs clearance for shipments.
                </p>
              </div>
            </div>
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
              <h3 className="mb-4">All Freight Forwarding Solutions</h3>
              <div className="" style={{ padding: "30px" }}>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Sea">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Sea Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Air">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Air Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Surface">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Surface Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Rail">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Rail Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Customs">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Customs Clearance
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Warehousing">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Warehousing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      <Footer />
    </>
  );
}
