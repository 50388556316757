import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <> <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
        <img className="text-primary me-3" style={{height:"100px"}} src="img/logo.png" /> <br/>
          
          <p>Harland International is a publicly listed freight forwarding and trading service provider company. Our approach is rooted in cutting-edge technology, aiming to deliver tailor-made logistic solutions globally to our clients. </p>
        
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Address</h4>
          <p><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
          <p><i className="fa fa-phone-alt me-3" />{companynumber}</p>
          <p><i className="fa fa-envelope me-3" />{companyemail}</p>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Quick Links</h4>
          <Link className="btn btn-link" to="/Home">Home</Link>
          <Link className="btn btn-link" to="/About">About</Link>
          <Link className="btn btn-link" to="/Services">Services</Link>
          <Link className="btn btn-link" to="/Trading">Trading Solutions</Link>
          <Link className="btn btn-link" to="/Contact">Contact</Link>
          <Link className="btn btn-link" to="/Getquote">Getquote</Link>
        </div><div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Freight Solutions</h4>
          <Link to="/Air" className="btn btn-link">
                  Air Freight
                </Link>
                <Link to="/Sea" className="btn btn-link">
                  Sea Freight
                </Link>
                <Link to="/Rail" className="btn btn-link">
                  Rail Freight
                </Link>
                <Link to="/Surface" className="btn btn-link">
                  Surface Freight
                </Link>
                <Link to="/Customs" className="btn btn-link">
                  Customs Clearance
                </Link>
                <Link to="/Warehousing" className="btn btn-link">
                  Warehousing Service
                </Link>
        </div></div>
    </div>
    <div className="container-fluid copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            © <Link to="/">{companyname}</Link>, All Right Reserved.
          </div>
          <div className="col-md-6 text-center text-md-end">
            <Link to="/Privacy">Privacy Policy </Link>
            <Link to="/Terms">Terms </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Back to Top */}
  <Link to="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></Link>
    </>
  );
};

export default Footer;
