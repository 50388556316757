import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Air Freight</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Air Freight</p>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="pb-3">
              <div className="position-relative">
                <img className="img-fluid w-100" src="img/air.webp" alt="" />
              </div>
              <div className=" mb-3" style={{ padding: "30px" }}>
                <h3 className="font-weight-bold mb-3">Air Freight</h3>
                <p>
                {companyname} is a renowned air freight forwarder
                  that specializes in handling both perishables and general
                  cargo. We have
                  established a strong reputation for delivering efficient and
                  reliable air freight services. Our global network spans across
                  150+ countries, providing us with a profound understanding of
                  the challenges businesses encounter in air freight logistics.
                </p>

                <p>
                  Our expertise shines particularly in managing
                  frozen-temperature-controlled shipments, including
                  pharmaceuticals, vaccines, bulk drugs, vegetables, fruits,
                  flowers, electronics/machinery, garments, and tissue culture.
                  This proficiency sets us apart in the industry. Recognizing
                  the importance of handling such shipments with utmost care and
                  precision, we have meticulously curated a team of experts
                  proficient in managing all varieties of perishable goods.
                </p>

                <p>
                  At {companyname}, we firmly believe that each
                  client is unique, and we steer clear of the one-size-fits-all
                  approach to air freight solutions. We take pride in offering
                  tailored solutions designed to cater to the distinctive needs
                  of each client. Our passionate team of professionals is
                  committed to delivering innovative and adaptable air freight
                  solutions that set new standards in the industry.
                </p>

                <p>
                  Our affiliation with IATA and partnerships with over 100
                  airlines and charter operators enable us to provide an array
                  of air freight services that are not only flexible and
                  reliable but also cost-effective. We work hand-in-hand with
                  our partners to ensure we deliver the most optimal air freight
                  solutions to our clients. Our comprehensive services encompass
                  door-to-door delivery, charter flights, temperature-controlled
                  shipments, and more.
                </p>

                <p>
                  <strong>Door-to-Door Delivery:</strong> Understanding the
                  significance of punctual delivery, our door-to-door service
                  guarantees that your cargo is collected from its origin and
                  directly delivered to its destination. We manage all facets of
                  the delivery process, including customs clearance and
                  final-mile delivery.
                </p>

                <p>
                  <strong>Charter Flights:</strong> For time-sensitive or
                  high-value shipments, our charter flight service offers a
                  swift and dependable solution. Collaborating with our
                  partners, we arrange dedicated aircraft to transport your
                  cargo, ensuring swift and secure arrival at its destination.
                </p>

                <p>
                  <strong>Temperature-Controlled Shipments:</strong> We excel in
                  handling temperature-controlled shipments, encompassing
                  pharmaceuticals, vaccines, bulk drugs, vegetables, fruits,
                  flowers, electronics/machinery, garments, and tissue culture.
                  We comprehend the criticality of preserving the cold chain
                  during transit and have invested in cutting-edge facilities
                  and equipment to guarantee that your cargo remains at the
                  requisite temperature throughout its journey.
                </p>

                <p>
                  In addition to our core offerings, we extend an array of
                  value-added services to ensure a seamless air freight process.
                  These services encompass warehousing, customs clearance,
                  documentation, insurance, and more.
                </p>

                <p>
                  At {companyname}, we are resolute in delivering
                  dependable and effective air freight solutions tailored to
                  each client's unique requirements. Our team of experts is
                  perpetually available to offer insights and guidance on the
                  optimal logistics solutions for your business. Get in touch
                  with us today to discover how we can assist you with your air
                  freight needs.
                </p>
              </div>
            </div>
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="mb-5">
              <h3 className="mb-4">All Freight Forwarding Solutions</h3>
              <div className="" style={{ padding: "30px" }}>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Sea">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Sea Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Air">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Air Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Surface">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Surface Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Rail">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Rail Freight
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Customs">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Customs Clearance
                    </Link>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <Link className="text-dark" to="/Warehousing">
                      <i className="fa fa-angle-right text-primary mr-2" />
                      Warehousing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      <Footer />
    </>
  );
}
