import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
      <div
        className="jumbotron jumbotron-fluid mb-5"
        style={{
          background:
            " linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/header.jpg)",
        }}
      >
        <div className="container text-center py-5">
          <h1 className="text-white display-3">Our Freight & Trading Solutions</h1>
          <div className="d-inline-flex align-items-center text-white">
            <p className="m-0">
              <Link className="text-white" to="/Home">
                Home
              </Link>
            </p>
            <i className="fa fa-circle px-3" />
            <p className="m-0">Our Freight & Trading Solutions</p>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Services</h6>
                <h1 className="display-6 mb-0">
                  Freight Forwarding Services We Provide..
                </h1>
              </div>
            </div>
            <div
              className="col-lg-6 text-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
             
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/air.webp" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Air Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Air">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/sea.jpeg" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Sea Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Sea">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/rail.png" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Rail Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Rail">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/road.jpg" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Surface Freight</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Surface">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/customs.png" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Customs Clearance</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Customs">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/warehousing.webp" style={{height:"240px"}} alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Warehousing</h5>
                  <p>
                  
                  </p>
                  <Link className="small" to="/Warehousing">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
     
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Services</h6>
                <h1 className="display-6 mb-0">Trading Services We Offer...</h1>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/apparel.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Apparel</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/wood.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Wood</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/food.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Food & Beverages</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/pharma.webp" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Pharma</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
               <Link to="/Trading"><img className="img-fluid" src="img/chemical.jpg" alt="" style={{height:"250px"}} /></Link> 
                <div className="team-text bg-white p-4">
                <Link to="/Trading"><h5>Chemicals & Petrochemicals</h5></Link> 
                  <span>Trading Service</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
